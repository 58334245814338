<template>
  <div class="avatar-wrapper">
    <template v-if="!loading">
      <v-avatar :size="size">
        <v-img v-if="user && user.picture" :src="user.picture">
          <template #placeholder>
            <v-icon
              :size="iconSize"
              :color="$vuetify.theme.dark ? '#fff' : '#888'"
              >mdi-account-circle</v-icon
            >
          </template>
        </v-img>
        <v-row v-else class="fill-height ma-0" align="center" justify="center">
          <v-icon
            class="BG"
            :color="$vuetify.theme.dark ? '#fff' : '#888'"
            :size="iconSize"
            >mdi-account-circle</v-icon
          >
        </v-row>
      </v-avatar>
      <v-icon
        v-if="isCelebrity"
        :size="celebrityIconSize"
        class="celebrity-badge"
      >
        $celebrityBadgeIcon
      </v-icon>
    </template>
    <template v-else>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="red" />
      </v-row>
    </template>
  </div>
</template>

<script>
import style from '@/mixins/style'
export default {
  name: 'AvatarComponent',
  mixins: [style],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 36,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    iconSize() {
      return this.size * 1.22
    },
    celebrityIconSize() {
      return this.size * 0.32
    },
    isCelebrity() {
      return this.user && this.user.accountType === 'celebrity'
    },
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = 'alt-image.jpg'
    },
  },
}
</script>

<style scoped>
.avatar-wrapper {
  position: relative;
}
.celebrity-badge {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
